// 档案
import {
  USER
} from '../api'
import {
  METHOD,
  request
} from '@/utils/request'

// 列表页-档案完善数据统计
export async function getArchivesStatistics(params) {
  return request(`${USER}/archives/getArchivesStatistics`, METHOD.GET, params)
}

// 列表页-查询档案列表
export async function getArchivesStatisticsList(params) {
  return request(`${USER}/archives/getArchivesStaffList`, METHOD.GET, params)
}

// 审批档案完善记录
export async function approve(params) {
  return request(`${USER}/archives/approve`, METHOD.POST, params)
}

// 列表页-查询员工的选择框
export async function getStaffChooseList(params) {
  return request(`${USER}/archives/getStaffChooseList`, METHOD.POST, params)
}

// 列表页-邀请员工完善
export async function invitationPerfect(params) {
  return request(`${USER}/archives/invitationPerfect`, METHOD.POST, params)
}

// 列表页-批量邀请员工完善
export async function invitationBatchPerfect(params) {
  return request(`${USER}/archives/invitationBatchPerfect`, METHOD.POST, params)
}

// 导出员工完善状态
export async function exportArchivesPerfect(params) {
  return request(`${USER}/archives/exportArchivesPerfect`, METHOD.GET, params)
}

// 加载待完善/待审核/已通过/未通过信息人员列表
export async function listArchivesPerfect(params) {
  return request(`${USER}/archives/listArchivesPerfect`, METHOD.GET, params)
}

// 列表页-通过部门查询档案员工列表
export async function getStaffList(params) {
  return request(`${USER}/archives/getStaffList`, METHOD.GET, params)
}

// 导出员工档案列表
export async function exportArchivesStaffList(params) {
  return request(`${USER + '/archives/exportArchivesStaffList'}`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}
