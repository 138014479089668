import { getTenantCode } from '@/services/dropDownInput/index.js'
export default {
  components: {
    appForm: () => import('../CbAppForm/index'),
    customTable: () => import('./index.vue')
  },
  data() {
    return {
      loading: false,
      lazyInitData: false,
      _queryParam: {},
      pager: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0
    }
  },
  async mounted() {
    // 请求数据以及基础的配置相关
    // this._queryParam = {
    //   ...fromData
    // }
    // this._queryParam.name = ''
    this._queryParam = { ...this._queryParam, ...this.pager }
    if (!this.lazyInitData) {
      this.$$fetchListWithLoading(this._queryParam)
    }
  },
  methods: {
    // 搜索获取数据 //此时存在分页信息 需要更新的是搜索信息
    serchGetData() {
      // this._queryParam = { ...this._queryParam, ...query }
      this._queryParam.pageNum = 1
      this._queryParam.pageSize = 10
      this.$$fetchListWithLoading(this._queryParam)
    },
    // 分页相关改变时 // 更新分页信息筛选信息 还有排序信息到 _queryParam
    async loadDataList({ current, pageSize, total }, filters = {}, sorter = {}) {
      this._queryParam = {
        ...this._queryParam,
        pageNum: current,
        pageSize,
        ...filters,
        sort: sorter.order && sorter.order === 'descend' ? '-' + sorter.field : '+' + sorter.field
      }
      if (sorter.order) {
        this._queryParam['sort'] = sorter.order === 'descend' ? '-' + sorter.field : '+' + sorter.field
      } else if (this._queryParam.sort) {
        delete this._queryParam.sort
      }
      this._queryParam.pageNum = current
      this.pager.pageNum = current
      this.pager.pageSize = pageSize
      this.total = total
      this.$$fetchListWithLoading(this._queryParam)
    },
    // 重置信息 // _queryParam 重置为默认数据
    resetDatalist() {
      // 重置表单数据
      this.queryForm.resetFields()
      const query = this.queryForm.getFieldsValue()
      const { pager } = this
      this.pager.pageNum = 1
      this._queryParam = { ...this._queryParam, ...pager, ...query }
      this.$$fetchListWithLoading(this._queryParam)
    },
    fetchDataApiFn() {
    },
    // 请求数据 // 请求数据_queryParam 更新分页信息中 currentPage 和 total信息
    async $$fetchListWithLoading(_queryParam) {
      this.loading = true
      await this.fetchDataApiFn(_queryParam)
      // 调用传入的api方法 获取数据
      // console.log('请求参数', _queryParam)
      // const { data: { data }} = await this.fetchDataApiFn(_queryParam)
      // console.log('相应', data)
      // const { current, pageSize } = _queryParam
      // this.dataSource = data.list
      // this.pager.currentPage = current || 1 // 更新当前的分页信息
      // this.pager.pageSize = pageSize || 10 // 更新当前的分页信息
      // this.pager.total = data.total // 接口获取更新当前的分页信息
      // // 更新分页信息
      // this._queryParam = { ...this._queryParam, ...this.pager }
      this.loading = false
    },
    // 字典项匹配
    async getTenantCode(codeName) {
      const res = await getTenantCode({ codeName })
      if (res.code === 100) {
        return res.data
      } else {
        return []
      }
    },
    getCodeContent(val, items) {
      const obj = items.filter(item => item.codeKey === String(val))
      return obj.length > 0 ? obj[0].content : ' '
    }
  }
}
