var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-content" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.showUserInfo,
            expression: "!showUserInfo",
          },
        ],
        staticClass: "user-list",
      },
      [
        _c("CbStatistics", {
          staticClass: "statistics",
          attrs: { "data-list": _vm.tabsList, length: "6" },
        }),
        _c("ArchiveTabel", {
          ref: "archiveTabel",
          on: { getInfoVisible: _vm.getInfoVisible },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }